<template>
    <div class="Receiving-manage bj">
        <div>
            <div class="seach order-wrapper" style="justify-content: space-between">
                <div class="title" v-if="role == 'delivery'" style="padding-top: 0;padding-left: 0;">
                    <el-button type="primary" icon="el-icon-refresh" class="bjsearch" @click="Refresh"></el-button>
                </div>
                <div class="d-flex" style="align-items: flex-start;flex-wrap: wrap;">
                    <div class="mr10">
                        <el-date-picker @change="payChange" v-model="pay_at" :time-arrow-control="true" type="datetimerange"
                            range-separator="—" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                            start-placeholder="支付时间起" end-placeholder="支付时间止">
                        </el-date-picker>
                    </div>
                    <div class="inputs">
                        <el-input placeholder="输入订单号" v-model="from.order_no" clearable></el-input>
                    </div>
                    <div class="inputs">
                        <el-input placeholder="输入客户电话" v-model="from.mobile" clearable></el-input>
                    </div>
                    <div class="inputs">
                        <el-input placeholder="输入合并批次号" v-model="from.delivery_take_order_batch_no" clearable></el-input>
                    </div>
                    <div class="inputs">
                        <el-select v-model="from.check_status" clearable placeholder="请选择核销状态">
                            <el-option label="全部核销状态" value="0"></el-option>
                            <el-option label="未核销" value="1"></el-option>
                            <el-option label="已核销" value="2"></el-option>
                        </el-select>
                    </div>
                    <div class="inputs" v-if="role == 'tdc'">
                        <el-select v-model="from.delivery_id" filterable clearable placeholder="请选择自提点">
                            <el-option :key="item.id" v-for="item in arrList" :label="item.name"
                                :value="item.id"></el-option>
                        </el-select>
                    </div>
                    <el-button type="primary" icon="el-icon-search" @click="search" v-if="role == 'delivery'">查询</el-button>
                </div>
            </div>
            <div class="title" v-if="role == 'tdc'">
                <el-button type="primary" icon="el-icon-refresh" class="bjsearch" @click="Refresh"></el-button>
                <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                <!-- <el-button type="primary" @click="print">合并提货单</el-button> -->

            </div>
        </div>
        <div class="auto-table-flex">
            <el-table :data="tableData" height="100%" v-loading="loading" :border="true"
                :header-cell-style="{ color: '#333333', background: '#EFF6FF' }" ref="multipleTable" tooltip-effect="dark"
                style="width: 100%">
                >
                <!-- <el-table-column type="selection" reserve-selection width="55">
                </el-table-column> -->
                <!-- <el-table-column type="index" width="100px" align="center" label="序号">
          </el-table-column> -->
                <el-table-column prop="order_no" align="center" label="订单编号">
                    <template slot-scope="scope">
                        {{ $empty.empty(scope.row.order_no) }}
                    </template>
                </el-table-column>
                <el-table-column prop="reveive_time" align="center" label="客户姓名">
                    <template slot-scope="scope">
                        <span v-if="scope.row.order && scope.row.order.member">{{
                            $empty.empty(scope.row.order.member.fullname) }}</span>
                        <span v-else>—</span>
                    </template>
                </el-table-column>
                <el-table-column prop="reveive_time" align="center" label="客户电话">
                    <template slot-scope="scope">
                        <span v-if="scope.row.order && scope.row.order.member">{{
                            $empty.empty(scope.row.order.member.mobile) }}</span>
                        <span v-else>—</span>
                    </template>
                </el-table-column>
                <el-table-column prop="reveive_time" align="center" label="自提点名称">
                    <template slot-scope="scope">
                        <div v-if="role == 'delivery'">
                            <span v-if="scope.row.order && scope.row.order.delivery">{{
                                $empty.empty(scope.row.order.delivery.name) }}</span>
                            <span v-else>—</span>
                        </div>
                        <div v-else>
                            <span v-if="scope.row.order && scope.row.order.store_delivery">{{
                                $empty.empty(scope.row.order.store_delivery.name) }}</span>
                            <span v-else>—</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="print_status" align="center" label="合并批次号">
                    <template slot-scope="scope">
                        <span v-if="scope.row.store_delivery_take_order_batch">{{
                            $empty.empty(scope.row.store_delivery_take_order_batch.delivery_take_order_batch_no) }}</span>
                        <span v-else>—</span>

                    </template>
                </el-table-column>
                <el-table-column prop="order_at" align="center" label="支付时间">
                    <template slot-scope="scope">
                        <span v-if="scope.row.order">{{ $empty.empty(tool.timestampToTime(scope.row.order.order_at))
                        }}</span>
                        <span v-else>—</span>
                    </template>
                </el-table-column>
                <el-table-column prop="check_status" align="center" label="核销状态">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.check_status == 2">已核销</el-tag>
                        <el-tag type="warning" v-else>未核销</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="check_time" align="center" label="核销时间">
                    <template slot-scope="scope">
                        {{ $empty.empty(tool.timestampToTime(scope.row.check_time)) }}
                    </template>
                </el-table-column>
                <el-table-column prop="check_user_name" align="center" label="核销员">
                    <template slot-scope="scope">
                        {{ $empty.empty(scope.row.check_user_name) }}
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleEdit(scope.row)">查看</el-button>
                        <!-- <el-button type="text" @click="printlist(scope.row)">打印</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="fy">
            <el-pagination type="primary" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="from.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { Loading } from 'element-ui';
import { BASE } from "../../api/index";
export default {
    name: "Receiving",
    components: {},
    data() {
        return {
            //列表新建页面初始参数
            loading: false,
            role: sessionStorage.getItem('manageIsdentity'),
            pay_at: '',
            from: {
                order_no: "",
                delivery_id: "",
                mobile: "",
                pay_at: {
                    start_time: "",
                    end_time: ""
                },
                delivery_take_order_batch_no: "",
                check_status: "0",
                page: 1,
                pageSize: 10,
            },
            from1: {
                order_no: "",
                delivery_id: "",
                mobile: "",
                pay_at: {
                    start_time: "",
                    end_time: ""
                },
                delivery_take_order_batch_no: "",
                check_status: "0",
                page: 1,
                pageSize: 10,
            },
            value1: [],
            arrList: [],
            tableData: [],
            currentPage: 1,
            total: 0,
            multipleSelection: [],
            lsttables: [],
        };
    },
    created() {
        console.log(window.location.href)
        if ((window.location.href).indexOf('?') > -1) {
            let url = this.tool.getUrl(window.location.href)
            this.from = url
            this.from1 = url
            this.pay_at = [this.from.pay_at.start_time, this.from.pay_at.end_time]
            this.currentPage = Number(this.from.page)
            this.from.pageSize = Number(this.from.pageSize)
        }
        this.hqlist();
        if (this.role == 'tdc') {
            this.from.delivery_id = Number(this.from.delivery_id) ? Number(this.from.delivery_id) : ''
            this.from1.delivery_id = Number(this.from1.delivery_id) ? Number(this.from1.delivery_id) : ''
            this.getDeliveryList();
        }
    },
    methods: {
        getDeliveryList() {
            this.$api.delivery.deliverylist().then((res) => {
                console.log(res, "获取数据");
                this.arrList = res.data;
            });
        },
        getKey(row) {
            return row.id;
        },
        payChange(val) {
            console.log(val)
            if (val) {
                // this.$set(this.from1.pay_at,'start_time',val[0])
                this.from.pay_at.start_time = val[0]
                this.from.pay_at.end_time = val[1]
            } else {
                this.from.pay_at.start_time = ''
                this.from.pay_at.end_time = ''
            }

        },
        toggleSelection(rows) {
            console.log(rows, "进入了吗");
            this.$nextTick(() => {
                if (rows) {
                    rows.forEach((el) => {
                        this.$refs.multipleTable.toggleRowSelection(el);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            });
        },
        printlist(el) {
            window.open(
                `${this.role=='delivery'?BASE.PRO3+'/order/print':BASE.PRO4+'/take/order/print'}` +
                `?ids=${el.id}&token=${sessionStorage.getItem("token")
                    ? sessionStorage.getItem("token")
                    : ""
                }`

            );
            this.hqlist();
            // this.$api.delivery.orderlisttiprint({ id: [el.id] }).then((res) => {
            //   console.log(res, "打印数据");
            //   this.hqlist();
            //   window.open(
            //     res.data +
            //       `?token:${
            //         sessionStorage.getItem("token")
            //           ? sessionStorage.getItem("token")
            //           : ""
            //       }`
            //   );
            // });
        },
        print() {
            this.$confirm("确定要合并提货单吗？", "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                }
            ).then(() => {
                console.log(this.from)
                let load = Loading.service({ fullscreen: false })
                this.$api.delivery.detailbatch(this.from).then((res) => {
                    console.log(res, "获取数据");
                    this.tool.message('合并成功', 'success')
                    load.close()
                    this.hqlist()
                }).catch(error => {
                    load.close()
                })
            }).catch(() => {
            })
        },
        handleSelectionChange(val) {
            console.log(val, "1111111111");
            this.lsttables = val;

            this.multipleSelection = val;
        },
        search() {
            // if (this.value1 != null) {
            //   if (this.value1.length > 0) {
            //     this.from.startDate = this.$time.time(this.value1[0]);
            //     this.from.endDate = this.$time.time(this.value1[1]);
            //     console.log(this.from.startDate, this.from.endDate, "时间");
            //   } else {
            //     this.from.startDate = "";
            //     this.from.endDate = "";
            //   }
            // } else {
            //   this.from.startDate = "";
            //   this.from.endDate = "";
            // }
            this.from.page = 1;
            this.currentPage = 1;
            this.from1 = this.from;
            this.tool.getResult(this.from1, window.location.href)
            this.hqlist();
        },

        Refresh() {
            this.from = {
                order_no: "",
                delivery_id: "",
                mobile: "",
                pay_at: {
                    start_time: "",
                    end_time: ""
                },
                delivery_take_order_batch_no: "",
                check_status: "0",
                page: 1,
                pageSize: 10,
            };
            this.from1 = {
                order_no: "",
                delivery_id: "",
                mobile: "",
                pay_at: {
                    start_time: "",
                    end_time: ""
                },
                delivery_take_order_batch_no: "",
                check_status: "0",
                page: 1,
                pageSize: 10,
            };
            this.lsttables = [];
            this.value1 = [];
            this.currentPage = 1;
            this.tool.getResult(this.from1, window.location.href)
            this.pay_at = [this.from.pay_at.start_time, this.from.pay_at.end_time]
            this.hqlist();
        },
        handleSizeChange(val) {
            //   console.log(`每页 ${val} 条`);
            this.from.pageSize = val;
            this.from1.pageSize = val;
            this.tool.getResult(this.from1, window.location.href)
            this.hqlist();
        },
        handleCurrentChange(val) {
            this.from.page = val;
            this.from1.page = val;
            this.currentPage = val;
            this.tool.getResult(this.from1, window.location.href)
            this.hqlist();
        },
        handleEdit(row) {
            this.$store.commit("setRefresh", false);
            this.from1.currentPage = this.currentPage;
            this.from1.value1 = this.value1;
            sessionStorage.setItem("Paymentsearch", JSON.stringify(this.from1));
            this.$router.push({
                path: `/Receivingprintdetails`,
                query: {
                    id: row.order_no,
                },
            });
        },

        hqlist() {
            this.loading = true;
            let form = this.tool.DeepCopy({}, this.from)
            if (this.role == 'delivery' && form.hasOwnProperty('delivery_id')) {
                delete form.delivery_id
            }
            this.$api.delivery.orderlistti(form).then((res) => {
                console.log(res, "获取数据");

                this.tableData = res.data.data;
                this.total = res.data.total;
                this.loading = false;
                this.toggleSelection(this.lsttables);
            });
        },
    },
};
</script>
<style lang="scss">
.Receiving-manage {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    box-sizing: border-box;
    //   background: #ffffff;
    padding: 10px;

    .order-wrapper {
        display: flex;
        align-items: flex-start;
        background: #ffffff;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 10px;

        .inputs {
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .multiple-select {
            width: 250px;
        }
    }

    .title {
        background: #ffffff;
        padding: 0 15px 13px;
        box-sizing: border-box;
        display: flex;

        .seach {
            flex: 1;
            display: flex;
            justify-content: flex-end;

            .inputs {
                width: 207px;
                margin-right: 8px;
            }
        }

        .bjsearch {
            background: #333333;
            border-color: #333333;
        }
    }

    .auto-table-flex {
        flex-grow: 1;
        overflow-y: hidden;
    }

    .fy {
        // background-color: #000;
        box-sizing: border-box;
        padding: 10px 20px;
    }

    .contents {
        padding-left: 55px;
        padding-right: 55px;
        padding-top: 23px;

        box-sizing: border-box;

        .titlname {
            font-size: 17px;
            color: #21c2ac;
            margin-bottom: 25px;
        }

        .imgs {
            display: flex;

            .imglist {
                width: 165px;
                height: 123px;
                border: 1px dashed #a4adc5;
                padding: 14px 8px;
                text-align: center;
                border-radius: 7px;
                box-sizing: border-box;
                margin-right: 16px;

                .div {
                    margin-top: 20px;
                    padding-bottom: 20px;
                }

                // img{
                //     width: 100%;
                //     height: 100%;
                // }
            }
        }

        .disply {
            display: flex;
            margin-bottom: 22px;
        }

        .mr {
            margin-right: 31px;
        }

        .iteminput {
            display: flex;
            align-items: center;

            .wen {
                width: 77px;
                margin-right: 24px;
                color: #000;
                font-size: 14px;
                text-align: right;
            }

            .el-input {
                width: 302px;
            }
        }
    }
}

.colors {
    color: #fa884c;
}

.times {
    margin-right: 8px;
}
</style>
    